import React from "react";
import { BiCheck } from "react-icons/bi";
import Uber from "../../assets/uber.jpeg";
import Amazon from "../../assets/amazon.png";
import "./work.css";

const Work = () => {
  return (
    <section id="work">
      <h5>Find out about my</h5>
      <h2>Work</h2>

      <div className="container services__container">
        <div className="job-container">
          <div className="job-image">
            <img src={Uber} alt="Uber logo" />
          </div>
          <div className="job-description">
            <h3 className="job-title">Software Engineering Intern</h3>
            <h4>Marketplace Engineering</h4>
            <h5>Jun 2022 - Sep 2022</h5>
          </div>
        </div>

        <div className="job-container">
          <div className="job-image">
            <img src={Amazon} alt="Amazon logo" />
          </div>
          <div className="job-description">
            <h3 className="job-title">Software Engineering Intern</h3>
            <h4>Intelligent Cloud Control</h4>
            <h5>Jun 2021 - Sep 2021</h5>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
